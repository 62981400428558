import React, { useEffect, useState } from "react";

const getThemeFromLocalStorage = () => {
  try {
    return localStorage.getItem("theme");
  } catch (e) {
    return null;
  }
};

const ThemeToggler = (props) => {
  const [theme, setTheme] = useState(typeof window !== "undefined" ? window.__theme : getThemeFromLocalStorage());

  useEffect(() => {
    window.__onThemeChange = () => {
      setTheme(window.__theme);
    };
  }, []);

  function toggleTheme(theme) {
    window.__setPreferredTheme(theme);
  }

  return (
    <props.children
      theme={theme}
      toggleTheme={toggleTheme}
    />
  );
};

export default ThemeToggler;