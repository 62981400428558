import { FaMoon, FaSun } from "react-icons/fa";
import * as React from "react";
import { useEffect, useRef } from "react";
import ThemeToggler from "./ThemeToggler";
import { useHasMounted } from "../hooks/useHasMounted";

function prepareStars(context) {
  context.fillStyle = "white";
  context.beginPath();
  context.fillRect(4, 6, 1, 1);
  context.fillRect(2, 12, 1, 1);
  context.arc(8, 16, 1.1, 0, 360);
  context.arc(10, 9, 0.9, 0, 360);
  context.fillRect(12, 3, 1, 1);
  context.fillRect(14, 14, 1, 1);
  context.fill();
}

const DarkModeSwitch = () => {
  const canvas = useRef(null);
  const hasMounted = useHasMounted();

  useEffect(() => {
    if (hasMounted) {
      const context = canvas.current.getContext("2d");
      prepareStars(context);
    }
  }, [hasMounted]);

  if (!hasMounted) {
    return null;
  }

  return <ThemeToggler>
    {({ theme, toggleTheme }) => (
      <span>
      <input id="checkbox"
             type="checkbox"
             className="checkbox"
             onChange={e => toggleTheme(e.target.checked ? "dark" : "light")}
             checked={theme === "dark"}
      />
      <label htmlFor={"checkbox"} className={"checkbox-label"}>
        <canvas ref={canvas} id="starfield" width="22" height="22" />
                <span className="ball">{theme === "dark" ? <FaMoon className={"fa-moon"} /> :
                  <FaSun className={"fa-sun"} />}</span>
      </label>
    </span>
    )}
  </ThemeToggler>;
};

export default DarkModeSwitch;
